@font-face {
  font-family: "KeplerDisp";
  src: url("./assets/fonts/KeplerStd-Disp.otf");
}
@font-face {
  font-family: "KeplerLight";
  src: url("./assets/fonts/KeplerStd-Light.otf");
}

@media screen and (min-width: 1024px) {
body {
  margin: 0;
  font-family: "keplerDisp";
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/imgs/bg.png');
  background-repeat: no-repeat;
  background-position: center center;
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
  background-size: cover;
  color: white;
  height: 100vh; 
}
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (max-width: 767px) {
	body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-family: "keplerDisp";
    background-image: url('./assets/imgs/bgmb.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* height: 1000px;   */
  }
}