html,body{
  font-size: 16px;
  color: white;
}
// @media screen and (min-width: 1024px) {
.App {
  text-align: center;
  padding-right: 10%;
  padding-left: 10%;
  .logo {
    padding-top: 5%;
    height: 200px;
    pointer-events: none;
  }
  .phrase {
    text-align: right;
    padding-top: 10%;
    font-size: xx-large;
    padding-right: 10%;
    .line {
      padding-top: 3%;
      width: 3%;
    }
  }
  .construction {
    font-size: larger;
    padding-top: 10%;
    .elipse {
      height: 6px;
      width: 6px;
    }
    .orange {
      color: #00d2ff;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20%;
    font-family: 'KeplerLight';
    font-size: smaller;
    .together {
      display: flex;
      align-items: center;
      .img {
        .Mitikah {
          width: 65%;
        }
      }
      .address {
        padding-left: 10px;
        text-align: initial;
      }
    }
    .contact {
      text-align: right;
    }
    padding-bottom: 5%;
  }
}
a:link {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

// a:hover {
//   color: white;
//   background-color: rgba($color: #fff, $alpha: 0.2);
//   border-radius: 20px;
//   text-decoration: none;
// }

a:active {
  color: white;
  background-color: transparent;
  text-decoration: none;
}
// }

@media screen and (max-width: 767px) { 
  .App{
    .logo{
      height: 100px;
    }
    .phrase{
      .mb{
        display: none;
      }
    }
    // .construction{
    //   // font-size: x-small;
    // }
    .footer{
      .together{
      .img {
        .Mitikah {
          width: 40%;
        }
      }
      .address {
        padding-left: 1px;
        text-align: initial;
      }
    }
      font-size: 8px;
    }
  }
} 
